/* eslint-disable no-underscore-dangle */
const { pathPrefix = '', wujiClientUrl = '/p/' } = window.GLOBAL_INFO || {};

// 数据配置(无极1.0)的url
export const clientUrl = wujiClientUrl;

// micro-app 下的变量
export const MICRO_APP_BASE_ROUTE = (window.__MICRO_APP_ENVIRONMENT__ && window.__MICRO_APP_BASE_ROUTE__) || '';
export const MICRO_APP_ORIGIN = (window.__MICRO_APP_ENVIRONMENT__ && window.__MICRO_APP_PUBLIC_PATH__) ? new URL(window.__MICRO_APP_PUBLIC_PATH__).origin || '' : '';

// 设计模式下的基础path
export const PATH_PREFIX = pathPrefix;

export const BASE_PATH = `${MICRO_APP_BASE_ROUTE}${pathPrefix}/xy/`;

export const BASE_APP_PATH = `${MICRO_APP_BASE_ROUTE}${pathPrefix}/xy/app/`;

export const BASE_GROUP_APP_PATH = `${MICRO_APP_BASE_ROUTE}${pathPrefix}/xy/group/:groupId`;

export const DEFAULT_ENV_APP_ENV = `${MICRO_APP_BASE_ROUTE}${pathPrefix}/xy/app/prod/`; // 默认环境为prod

export const BASE_APP_PATH_REGEX = `${MICRO_APP_BASE_ROUTE}${pathPrefix}/xy/app/:env/:projectId`;

export const BASE_BRANCH_APP_PATH_REGEX = `${MICRO_APP_BASE_ROUTE}${pathPrefix}/xy/app/branch/:branch/:projectId`; // 分支下的运行路径

export const APP_PATH_REGEX = window?.RUNTIME_CONFIGS?.pathPrefix || BASE_APP_PATH_REGEX;

export const BASE_API = '';

export const BASE_API_PATH = `${MICRO_APP_ORIGIN}/api`;

export const BASE_XY_API_PATH = `${MICRO_APP_ORIGIN}/api/xy`;

export const DATA_SOURCE_API = `${BASE_API_PATH}/combo/user/schemas`; // 无极schema数据源

export const FIELDS_LIST_API = `${BASE_API_PATH}/xy/access/schema/{{schemaid}}?appid={{appid}}&projectid={{projectid}}`; // 扁平化字段列表

export const runtimePath = `${BASE_API_PATH}/xy/runtime`;

export const designPath = `${BASE_API_PATH}/xy/design`;
// 运行时环境路由前缀
export const getRuntimeEnvPath = env => `${runtimePath}/env/${env || 'dev'}`;

// 工具侧环境路由前缀
export const getDesignEnvPath = env => `${designPath}/env/${env || 'dev'}`;

// 运行时环境多环境多版本配置读取路由
export const getRuntimeManagePath = env => `${getRuntimeEnvPath(env)}/manage`;

// 环境控制路由
export const getControlEnvPath = env => `${BASE_API_PATH}/xy/control/${env || 'dev'}`;

// 读取多环境多版本指定环境路由
export const getManagePath = env => `${getControlEnvPath(env)}/manage`;

// 所欲预发布环境变量
export const stagingEnvs = window?.GLOBAL_INFO?.stagingEnvs ?? ['staging'];

// 预发布环境变量
export const stagingEnv = window?.GLOBAL_INFO?.stagingEnv ?? 'staging';

// 获取应用默认图标
export const getProjectDefaultLogo = (projectid) => {
  let initial = 'A';
  if (typeof projectid === 'string' && projectid.length > 0) {
    initial = projectid[0].toLocaleUpperCase();
  }
  return `https://vfiles.gtimg.cn/wuji_dashboard/xy/portal/${initial}.svg`;
};

// 无极保留的查询接口参数字段
export const reservedKeys = ['page', 'size', 'exclude', 'include', 'sort', 'order', 'group', 'ignore_case', 'filter', 'count', 'link', 'link_query', 'join', 'datetime'];

// GET 1. 查看单条配置
export const getObjectApi = `${BASE_API_PATH}/xy/access/object/{{objectid}}?appid={{appid}}&schemaid={{schemaid}}&projectid={{projectid}}`;

// POST 2. 修改单条配置
export const editObjectApi = `${BASE_API_PATH}/xy/access/object/patch/{{objectid}}?appid={{appid}}&schemaid={{schemaid}}&projectid={{projectid}}`;

// POST 3. 新增单条配置
export const addObjectApi = `${BASE_API_PATH}/xy/access/object?appid={{appid}}&schemaid={{schemaid}}&projectid={{projectid}}`;

// POST 4. 删除单条配置
export const deleteObjectApi = `${BASE_API_PATH}/xy/access/object/delete/{{objectid}}?appid={{appid}}&schemaid={{schemaid}}&projectid={{projectid}}`;

// GET 5. 获取列表总条数
export const getListCount = `${BASE_API_PATH}/xy/access/object?appid={{appid}}&schemaid={{schemaid}}&projectid={{projectid}}&size=total`;

// GET 6. 获取列表数据
export const getList = `${BASE_API_PATH}/xy/access/object?appid={{appid}}&schemaid={{schemaid}}&projectid={{projectid}}`;

// export const PAGE_SAVE_API = `${BASE_API}/api/page/save`;
export const WUJI_COMPONENTS = {
  // 自增ID
  100: {
    type: 'public-w-readonly-new',
  },
  // 自定义ID
  101: {
    type: 'public-w-input-2v',
  },
  // 随机ID
  102: {
    type: 'public-w-readonly-new',
  },
  // 伪自增ID
  103: {
    type: 'public-w-readonly-new',
  },
  // 新的随机ID
  104: {
    type: 'public-w-readonly-new',
  },
  // 文本输入框（单行）
  1: {
    type: 'public-w-input-2v',
  },
  // 多行文本输入框
  2: {
    type: 'public-w-textarea',
  },
  // Url链接
  3: {
    type: 'public-w-input-2v',
  },
  // 图片（url）
  4: {
    type: 'public-w-upload-file',
    props: {
      dropInVersion: 1,
      fileType: 'image',
      acceptSuffix: 'image/*',
    },
  },
  // 下拉选择框
  5: {
    type: 'public-w-select-2v',
  },
  // 智能选择框
  6: {
    type: 'public-w-select-2v',
  },
  // 关联对象
  7: {
    type: 'public-w-select-2v',
  },
  // 数字输入
  8: {
    type: 'public-w-number',
  },
  // CheckBox
  9: {
    type: 'public-w-boolean-2v',
  },
  // 时间，单位ms
  10: {
    type: 'public-w-date',
  },
  // 创建时间
  11: {
    type: 'public-w-date',
  },
  // 修改时间
  12: {
    type: 'public-w-date',
  },
  // 多选，下拉选择框
  13: {
    type: 'public-w-select-2v',
  },
  // 创建人
  14: {
    type: 'public-w-readonly-new',
  },
  // 颜色
  15: {
    type: 'public-w-color',
  },
  // 富文本
  16: {
    type: 'public-w-input-2v',
  },
  // 富文本
  17: {
    type: 'public-w-input-2v',
  },
  // 拖拽排序
  18: {
    type: 'public-w-readonly-new',
  },
  // 超大富文本
  19: {
    type: 'public-w-input-2v',
  },
  // JSON文本
  20: {
    type: 'public-w-json',
  },
  // 小数输入
  21: {
    type: 'public-w-number',
  },
  // 文件
  22: {
    type: 'public-w-input-2v',
  },
  // 配置管理人
  23: {
    type: 'public-w-rtx',
  },
  // 最近修改人
  24: {
    type: 'public-w-readonly-new',
  },
  // 代码块
  25: {
    type: 'public-w-input-2v',
  },
  // JSON对象
  26: {
    type: 'public-w-input-2v',
  },
  // RTX
  27: {
    type: 'public-w-rtx',
  },
  29: {
    type: 'public-w-exeditor3',
  },
};
export const WUJI_COMPONENTS_DEFAULT_PROPS = {
  'public-w-upload-file': {
    props: {
      dropInVersion: 1,
      fileType: 'image',
      acceptSuffix: 'image/*',
    },
  },
};

export const COMPONENT_HUB_API_PATH = `${BASE_API_PATH}/xy/common/componenthub`;

export const CUSTOM_HOST_CONFIG = window?.WUJI_SWITCH?.custom_host_config;
export const IS_SHOW_XIAOMAN = window.WUJI_SWITCH?.xiaoman_runtime?.open;
export const IS_SHOW_AEGIS = (window.WUJI_SWITCH?.aegis_config?.open && window?.GLOBAL_INFO?.open) ?? false;
export const IS_SHOW_AGENT_REPORT = window?.GLOBAL_INFO?.open ?? false;
export const OPEN_WUJI_NAVIGATOR = !!window?.WUJI_SWITCH?.wuji_navigator;
const { GLOBAL_INFO } = window;
export const HAS_PANGU_SERVICE = GLOBAL_INFO.deploySettingFlag?.hasPanguService;
export const HAS_POWER_SERVICE = GLOBAL_INFO.deploySettingFlag?.hasPowerService;
export const HAS_OTHER_AUTH_SERVICE = HAS_PANGU_SERVICE || HAS_POWER_SERVICE;
export const EXTERNAL_DEPLOY = GLOBAL_INFO.externalDeploy;
export const ENABLE_EMAIL_SETTING = !!GLOBAL_INFO.deploySettingFlag?.enableEmailSetting;

export const SAAS = GLOBAL_INFO.saas;
export const DEPLOY_WITH_LICENSE = GLOBAL_INFO.deployWithLicense;
export const ENABLE_ADVANCE_SETTING = !!GLOBAL_INFO.deploySettingFlag?.hasIntranetResourceURL;
export const OPEN_DEPLOY = GLOBAL_INFO.open;
export const OPEN_DESINGER_DEPLOY = OPEN_DEPLOY && GLOBAL_INFO.openDeployType === 'designer';
export const OPEN_RUNTIME_DEPLOY = OPEN_DEPLOY && (!GLOBAL_INFO.openDeployType || GLOBAL_INFO.openDeployType === 'runtime');
export const PROFILE_API_PREFIX = `${COMPONENT_HUB_API_PATH}/profile`;
export const ADVANCE_SETTING = window.xy_runtime_advance_setting || {};
export const getComponentHubApiPath = env => `${BASE_API_PATH}/xy/common/env/${env}/componenthub`;

// 应用组部署 为true时 主页切为grouplist 并且屏蔽applist的入口
export const GROUP_DEPLOY = GLOBAL_INFO.groupDeploy;
export const GROUP_SIDER_ROUTER_PATH = '/group-sider';
// 默认为true 启用无极2.0的全局左侧菜单
export const ENABLE_GROUPSIDER = GLOBAL_INFO.groupSider;
export const SSO_DEPLOY = GLOBAL_INFO.ssoEnable;

export const ENABLE_FLOWENGINE = GLOBAL_INFO.enableFlowEngine;

export const IS_SUPER_USER = window.GLOBAL_INFO.user_type === 'super';
export const OPEN_CREATE_APP = IS_SUPER_USER || window.GLOBAL_INFO.isOpenCreateApp;
export const OPEN_DELETE_APP = IS_SUPER_USER || window.GLOBAL_INFO.isOpenDeleteApp;

export const LOW_MEMORY_MODE = GLOBAL_INFO.lowMemoryMode;

// 系统默认的主题色(id为xy页面runtime模式下加载的css路径)
export const DEFAULT_THEME_COLOR_LIST = [
  {
    id: 'default',
    label: '默认红',
    mainColor: '#7F0020',
  },
  {
    id: '/xystatic/src/theme/tencentblue.css',
    alias: 'tencentblue',
    label: '腾讯蓝',
    mainColor: '#0052D9',
  },
  {
    id: 'antd',
    label: '天空蓝',
    mainColor: '#1890ff',
  },
];

export const THEME_COLOR_ON_DEV_MODE = {
  Default: 'default',
};

export const THEME_COLOR_SELECTOR_KEY = 'theme-color';

const getCustomThemeColorList = () => {
  try {
    const customTheme = JSON.parse(window.GLOBAL_INFO.customThemes || '[]').map(item => ({
      id: item.name,
      label: item.desc,
      mainColor: item.mainColor,
    }));
    return customTheme.filter(i => !DEFAULT_THEME_COLOR_LIST
      .find(defaultTheme => defaultTheme.id === i.id
        || defaultTheme.alias === i.id));
  } catch (err) {
    return [];
  }
};

// 部署自定义的主题色
export const CUSTOM_THEME_COLOR_LIST = getCustomThemeColorList();

// 可用于内置页面模板 id，不用获取插件信息及上报
export const BUILTIN_PAGE_ID = '_BUILTIN_PAGE_ID_';
export const generatedPageIdPrefix = '!generated:';

export const WORKFLOW_ROUTE_NAME = 'workflow.entity-detail';
export const JOB_FLOW_ROUTE_NAME = 'workflow.new.flow-detail';
export const SKIN_API_PATH = `${BASE_XY_API_PATH}/skin`;
